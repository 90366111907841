//homepage-type-circle-banner-widget
$homepage-type-circle-banner-widget-margin: 50px auto 0;
$homepage-type-circle-banner-widget-image-grid-margin: 0 -13px;

$homepage-type-circle-banner-widget-item-padding: 0 13px;
$homepage-type-circle-banner-widget-item-width: 25%;

$homepage-type-circle-banner-widget-details-position-left: 13px;
$homepage-type-circle-banner-widget-details-position-right: 13px;


$homepage-type-circle-banner-widget-link-width        : 276px;
$homepage-type-circle-banner-widget-link-height       : 136px;
$homepage-type-circle-banner-widget-link-background-image: url('../images/home/banner/im-background-cicrle-widget.png');


$homepage-type-circle-banner-widget-link-font-family  : $font-family-secondary;
$homepage-type-circle-banner-widget-link-font-size    : 25px;
$homepage-type-circle-banner-widget-link-font-weight  : 600;
$homepage-type-circle-banner-widget-link-font-color   : $gray-dark2;
$homepage-type-circle-banner-widget-link-font-color-hover   : $red-dark;
$homepage-type-circle-banner-widget-link-line-height  : 30px;
$homepage-type-circle-banner-widget-link-padding: 5px 0 0;

$homepage-type-circle-banner-widget-link-after-max-width: 65px;
$homepage-type-circle-banner-widget-link-after-height: 3px;
$homepage-type-circle-banner-widget-link-after-background: $red-light;
$homepage-type-circle-banner-widget-link-after-margin: 5px auto 0;

//tablet => homepage-type-circle-banner-widget
$homepage-type-circle-banner-widget-margin\tablet: 35px auto 0;

$homepage-type-circle-banner-widget-image-grid-margin\tablet: 0 -8px;
$homepage-type-circle-banner-widget-item-padding\tablet: 0 8px;

$homepage-type-circle-banner-widget-link-width\tablet        : 150px;
$homepage-type-circle-banner-widget-link-height\tablet       : 74px;

$homepage-type-circle-banner-widget-link-font-size\tablet    : 16px;
$homepage-type-circle-banner-widget-link-line-height\tablet  : 18px;

$homepage-type-circle-banner-widget-link-after-max-width\tablet: 35px;
$homepage-type-circle-banner-widget-link-after-height\tablet: 2px;
$homepage-type-circle-banner-widget-link-after-margin\tablet: 3px auto 0;

//mobile => homepage-type-circle-banner-widget
$homepage-type-circle-banner-widget-margin\mobile: 14px 0 0;

$homepage-type-circle-banner-widget-image-grid-margin\mobile: 0 -8px;
$homepage-type-circle-banner-widget-item-padding\mobile: 16px 8px 0;

$homepage-type-circle-banner-widget-details-position-left\mobile: 5px;
$homepage-type-circle-banner-widget-details-position-right\mobile: 5px;

$homepage-type-circle-banner-widget-link-width\mobile        : 150px;
$homepage-type-circle-banner-widget-link-height\mobile       : 74px;



//home-type-rectangle-banner-widget
$home-type-rectangle-banner-widget-margin: 34px 0 0;

$home-type-rectangle-banner-widget-images-grid-margin: 0 -8px;

$home-type-rectangle-banner-widget-image-item-padding: 16px 8px 0;

$home-type-rectangle-banner-widget-details-align-content-left: justify-content;
$home-type-rectangle-banner-widget-details-align-content-left-padding: 0 0 0 8.4%;
$home-type-rectangle-banner-widget-details-align-content-left-text-align: left;

$home-type-rectangle-banner-widget-details-align-content-right: flex-end;
$home-type-rectangle-banner-widget-details-align-content-right-padding: 0 8.4% 0 0;
$home-type-rectangle-banner-widget-details-align-content-right-text-align: right;

$home-type-rectangle-banner-widget-details-align-content-middle: center;
$home-type-rectangle-banner-widget-details-align-content-middle-padding: 0;
$home-type-rectangle-banner-widget-details-align-content-middle-text-align: center;


$home-type-rectangle-banner-widget-title-font-family: $font-family-secondary;
$home-type-rectangle-banner-widget-title-font-size: 40px;
$home-type-rectangle-banner-widget-title-font-color: #FFF;
$home-type-rectangle-banner-widget-title-line-height: 48px;
$home-type-rectangle-banner-widget-title-font-weight: 600;
$home-type-rectangle-banner-widget-title-text-shadow: 0 2px 4px rgba(0,0,0,0.15);

$home-type-rectangle-banner-widget-link-font-family: $font-family-base;
$home-type-rectangle-banner-widget-link-font-size: 16px;
$home-type-rectangle-banner-widget-link-font-color: #FFF;
$home-type-rectangle-banner-widget-link-line-height: 22px;
$home-type-rectangle-banner-widget-link-font-weight: $font-weight-bold;
$home-type-rectangle-banner-widget-link-text-transform: uppercase;
$home-type-rectangle-banner-widget-link-text-shadow: 0 2px 4px rgba(0,0,0,0.3);
$home-type-rectangle-banner-widget-link-border: 2px solid #FFF;
$home-type-rectangle-banner-widget-link-padding: 12px 34px;
$home-type-rectangle-banner-widget-link-margin: 17px 0 0;
$home-type-rectangle-banner-widget-link-background-hover: $red-light;
$home-type-rectangle-banner-widget-link-border-color-hover: $red-light;


//tablet => home-type-rectangle-banner-widget
$home-type-rectangle-banner-widget-margin\mobile: 20px 0 0;

$home-type-rectangle-banner-widget-title-font-size\tablet: 25px;
$home-type-rectangle-banner-widget-title-line-height\tablet: 30px;

$home-type-rectangle-banner-widget-link-font-size\tablet: 13px;
$home-type-rectangle-banner-widget-link-line-height\tablet: 18px;
$home-type-rectangle-banner-widget-link-padding\tablet: 9px 16px;
$home-type-rectangle-banner-widget-link-margin\tablet: 8px 0 0;

$homepage-banner-widget-image-transition: all .3s ease-in-out;
$homepage-banner-widget-image-transform: scale(1.2);

