$minicart-content__action-button-bg-color: $red-light;

$minicart-background-color: #FFF;

$input-discount-border-color: $red-dark;

$button-discount-bg-color: $red-dark;
$button-discount-bg-color-hover: $red-light;

$button-add-all-color: $red-dark;
$button-add-all-border-color: $red-dark;

$button-color-added: $red-dark;

$success-message-color: #CB3C35;
