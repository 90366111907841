
//$section__heading-color                 : $color-accent-secondary;
$section__heading-color                 : #292F38;
$section__heading-background-color      : transparent;
$section__heading-font-weight           : $font-weight-medium;
$section__heading-font-size             :40px;


$section__heading-background-width: 77px;
$section__heading-background-height: 2px;
$section__heading-background-size: cover;
$section__heading-background-before-url: url('../images/impromptu-section__heading-bg.svg');
$section__heading-background-after-url: url('../images/impromptu-section__heading-bg.svg');
