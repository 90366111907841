$mega-menu-secondary__background-color              : #f2f1ec;
$mega-menu-secondary__link-color                    : #292F38;
$mega-menu-secondary__link-color--hover             : $red-dark;
$mega-menu-secondary__list-background-color         : #f2f1ec;
$mega-menu-secondary__link-text-decoration--hover   : none;
$mega-menu-secondary__link-font-weight              : 600;


$mega-menu__inner-link-color--hover: $red-dark;

$mega-menu__link-font-size                          : 20px;
$mega-menu__link-font-weight                        : 600;
$mega-menu__link-text-transform                     : none;
$mega-menu__link-font-family                        : $font-family-serif;
$mega-menu__link-padding                    : 0 40px;

$mega-menu__inner-link-padding: 0 40px;

$mega-menu-link-before-background: $red-dark;

//inner link
$mega-menu__inner-link-font-size                    : $font-size-medium;

