$title-swirl-font-family: $font-family-secondary;
$title-swirl-font-size: 40px;
$title-swirl-font-color: $gray-dark2;
$title-swirl-font-weight: 600;
$title-swirl-line-height: 48px;
$title-swirl-text-align: center;
$title-swirl-margin: 0 auto;


$title-swirl-before-after-width: 50px;
$title-swirl-before-after-height: 2px;
$title-swirl-before-after-background-image: url('../images/home/icons/im-swirl.svg');

$title-swirl-before-margin: 0 20px 0 0;
$title-swirl-after-margin: 0 0 0 20px;

//tablet

$title-swirl-font-size\tablet: 25px;
$title-swirl-line-height\tablet: 30px;

$title-swirl-before-margin\tablet: 0 10px 0 0;
$title-swirl-after-margin\tablet: 0 0 0 10px;