.header {
    .search-form__input{

        @include mq($screen-l, $screen-xl ) {
            &::-webkit-input-placeholder {
               font-size: 13px;
            }
            &:-ms-input-placeholder {
                font-size: 13px;
            }
            &::placeholder {
                font-size: 13px;
            }
        }
        @include mq($max-screen: $screen-m - 1) {
            &::-webkit-input-placeholder {
                font-size: 13px;
            }
            &:-ms-input-placeholder {
                font-size: 13px;
            }
            &::placeholder {
                font-size: 13px;
            }
        }
    }
    &__logo{
        @include mq($screen-l){
           max-width: 15vw;
        }
        @include mq($screen-xl + 140){
           max-width: 250px;
        }
    }
}
@media only screen and (max-width: 768px) {
    body#html-body iframe[title="iAdvize chat notification frame"][style*="position: fixed"]{
        bottom: 105px !important;
    }

    body#html-body iframe#chatboxreduced-iframe {
        bottom: 105px !important;
    }

    body#html-body .uwy  .userway_buttons_wrapper {
        bottom: 150px !important;
        top: unset !important;
        right: 0  !important;

    }
}
