#modal-minicart{
  .yotpo.bottomLine .yotpo-bottomline .yotpo-icon-empty-star,
  .yotpo.bottomline .yotpo-bottomline .yotpo-icon-empty-star,
  .yotpo.bottomLine .yotpo-bottomline .yotpo-icon-half-star,
  .yotpo.bottomline .yotpo-bottomline .yotpo-icon-half-star,
  .yotpo.bottomLine .yotpo-bottomline .yotpo-icon-star,
  .yotpo.bottomline .yotpo-bottomline .yotpo-icon-star,
  .yotpo .yotpo-modal-bottom-line .yotpo-bottomline .yotpo-icon-empty-star,
  .yotpo .yotpo-modal-bottom-line .yotpo-bottomline .yotpo-icon-half-star,
  .yotpo .yotpo-modal-bottom-line .yotpo-bottomline .yotpo-icon-star{
    color: $red-dark;
  }
}
.minicart-content__bottom{
  .form-discount{
    .discount-option-inner{
      input[type=text]{
        font-size: 14px;
        ~ label{
          font-size: 14px;
          top: 9px;
        }
      }
    }
    .actions-toolbar .action{
      font-weight: 700;
    }
  }
}


#popup-upsell-cart-inside {

    .section__heading h2{
        color: $red-light;
        font-size: 32px;

        &:before,
        &:after{
            background-size: 100% 100%;
            width: 77px;
        }
    }

    .section__content--products{
        .product-grid-item__name{
            font-size: 18px;
        }
        .product-grid-item--slider-item{
            .product-grid-item__details-bottom{
                .product-grid-item__actions{
                    .product-grid-item__primary-action{
                        .button--add-to{
                            font-size: 14px;
                            font-weight: 700;
                            letter-spacing: -0.35px;
                        }

                    }
                }
                .minimal-price .price,
                .price__value{
                    font-size: 14px;
                }
            }
        }

    }

}
