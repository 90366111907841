$slider__dots-width: 150px;
$slider__dots-height: 50px;
$slider__dots-padding: 22px 0 0;
$slider__dots-bottom-position: -20px;
$slider__dots-background-image: url('../images/home/icons/im-bg-slider-dots.svg');

$slider__dots-button-border: 2px solid #B6B8BA;
$slider__dots-button-background-active:$gray-dark2;
$slider__dots-button-border-color-active: $gray-dark2;


//tablet
$slider__dots-button-width\tablet: 11px;
$slider__dots-button-height\tablet: 11px;
$slider__dots-button-margin\tablet: 0 4px;

$slider__dot-size: 13px;
$slider__dot-background-color: transparent;
