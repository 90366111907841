/*Gotham*/
@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Bold.woff2') format('woff2'),
    url('../fonts/Gotham-Bold.woff') format('woff'),
    url('../fonts/Gotham-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Book.woff2') format('woff2'),
    url('../fonts/Gotham-Book.woff') format('woff'),
    url('../fonts/Gotham-Book.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Light.woff2') format('woff2'),
    url('../fonts/Gotham-Light.woff') format('woff'),
    url('../fonts/Gotham-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Medium.woff2') format('woff2'),
    url('../fonts/Gotham-Medium.woff') format('woff'),
    url('../fonts/Gotham-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


/*Archer*/
@font-face {
    font-family: 'Archer';
    src: url('../fonts/archer-bold.woff2') format('woff2'),
    url('../fonts/archer-bold.woff') format('woff'),
    url('../fonts/archer-bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Archer';
    src: url('../fonts/Archer-Light.woff2') format('woff2'),
    url('../fonts/Archer-Light.woff') format('woff'),
    url('../fonts/Archer-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Archer';
    src: url('../fonts/archer-book.woff2') format('woff2'),
    url('../fonts/archer-book.woff') format('woff'),
    url('../fonts/archer-book.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Archer';
    src: url('../fonts/Archer-Medium.woff2') format('woff2'),
    url('../fonts/Archer-Medium.woff') format('woff'),
    url('../fonts/Archer-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Archer';
    src: url('../fonts/Archer-Semibold.woff2') format('woff2'),
    url('../fonts/Archer-Semibold.woff') format('woff'),
    url('../fonts/Archer-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}




