.cart-discount{
  &__content-button{
    color: $red-light;

    &.action.check{
      color: $red-light ;
      @include mq($screen-m){
        max-width: calc(100% - 145px);
      }

      @include mq($screen-l,$max-screen: $screen-xl ) {
        max-width: calc(100% - 80px);
      }

    }
  }

}


.checkout-cart-index{
  .cart-discount{
    &__content-button{
      &.action.check{
        @include mq($max-screen: $screen-m - 1){
          color: #292F38;
        }
      }
    }
  }
}
