.newsletter {
    .checkbox__label:after{
        top: 10px;
    }
    .checkbox__icon{
        top: 11px;
    }

    @include mq($max-screen: $screen-m - 1){
        .newsletter__catalog-container{
            height: 120px;
            .newsletter__catalog-title{
                margin-bottom: 10px;
            }
        }
    }
}
