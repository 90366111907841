.cms-contact-us {
    .heading--page:after{
        bottom: -30px;
        width: 80px;
    }
    .select2-container{
        .select2-results__option{
            font-size: 16px;
        }
        .select2-selection--single .select2-selection__rendered{
            font-size: 16px;
        }
    }
    .contact-us__tips{
        font-size: 12px;
    }
    .p-message{
        a{
            font-weight: 500;
        }
    }
    .webforms-success-text{
        font-size: 16px;
    }
}
