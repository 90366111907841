.product-view {
    &__stock {
        color: $color-primary;
    }

    &__title {
        &.heading--page {
            color: $color-accent-secondary;
        }
    }
}
.catalog-product-view {
    .page-main .product-view .yotpo .yotpo-bottomline{
        .yotpo-icon-star,
        .yotpo-icon-empty-star,
        .yotpo-icon-half-star{
            color: $red-dark !important;
        }
    }
}

.catalog-category-view{
    .catalog-list__sidebar .filters .filters__item .filters__trigger{
        @include mq($max-screen: $screen-m - 1){
            font-size: 14px;
        }
        @include mq($max-screen: $screen-xss - 1){
            font-size: 12px;
            padding-right: 2px;
            padding-left: 2px;
        }
    }
    .toolbar__sorter .select2{
        @include mq($max-screen: $screen-m - 1){
            min-width: 140px;
        }
        @include mq($max-screen: $screen-xss - 1){
            min-width: 110px;
        }
    }
    .toolbar__sorter-label,
    .toolbar__limiter-label{
        @include mq($max-screen: $screen-xss - 1){
            font-size: 12px;
        }
    }

    .toolbar-products .select2-container .select2-selection--single .select2-selection__rendered{
        @include mq($max-screen: $screen-xss - 1){
            font-size: 12px;
            padding-left: 10px;
            padding-right: 30px;
        }
    }

    .select2-container .select2-selection--single .select2-selection__arrow{
        @include mq($max-screen: $screen-xss - 1){
            right: 8px;
            width: 12px;
            b{
                padding: 3px;
                margin-top: -5px;
            }
        }
    }

}
.callout-icons-box{
    .callout-icons{
        color: $red-light;
    }
    .callout-icon{
        .icon{
            fill: $red-light;
        }
    }
}
