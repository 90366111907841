$newsletter__title-font-size: 30px;

$newsletter__subtitle-font-size: 16px;
$newsletter__subtitle-font-weight: 300;

$newsletter__checkbox-label-font-size: 16px;

$newsletter__catalog-link-font-size: 16px;
$newsletter__catalog-link-color: $red-dark;
$newsletter__catalog-link-color-hover: $red-dark;


//tablet
$newsletter__subtitle-font-size\tablet: 14px;

$newsletter__checkbox-label-font-size\tablet: 14px;

$newsletter-button-font-size\tablet: 14px;

$newsletter__catalog-link-font-size\tablet: 13px;
$newsletter__catalog-link-line-height\tablet: 15px;

$newsletter__catalog-link-before-margin\tablet: 0;
