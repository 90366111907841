$header__search-form-input-font-size : 16px;
$header__search-form-input-font-weight  : $font-weight-normal;

$header-minicart-text-font-size : 16px;
$header-minicart-text-font-color-hover: $red-dark;

$header-button__counter-background: $red-dark;

$header-button__summary-count-font-size: 14px;


//tablet
$header__search-form-input-font-size-tablet: 16px;
