$recent-raves-widget-padding: 50px 0 0;
$recent-raves-widget-margin: 0;


$review-block-content-padding: 0 25px;

$review-item-box-shadow: 0 0 7px 0 rgba(0,0,0,0.13);

$review-product-name-font-family: $font-family-secondary;
$review-product-name-font-size: 20px;
$review-product-name-font-weight: 600;
$review-product-name-line-height: 22px;
$review-product-name-color: $gray-dark2;
$review-product-name-height: 46px;
$review-product-name-margin: 15px 0;


$review-title-font-family: $font-family-base;
$review-title-font-size: 18px;
$review-title-line-height: 20px;
$review-title-color: $gray-light2;
$review-title-margin: 15px 0 0;


$review-paragragh-font-family: $font-family-base;
$review-paragragh-font-size: 15px;
$review-paragragh-line-height: 21px;
$review-paragragh-color: $gray-light2;
$review-paragragh-margin: 10px 0 0;

$review-yotpo-color: $red-light;

//tablet
$review-product-name-font-size\tablet: 16px;
$review-product-name-line-height\tablet: 20px;
$review-product-name-height\tablet: auto;
$review-product-name-margin\tablet: 10px 0;

$review-title-font-size\tablet: 14px;
$review-title-font-weight\tablet: 300;

$review-paragragh-font-size\tablet: 13px;
$review-paragragh-line-height\tablet: 19px;
$review-paragragh-font-weight\tablet: 300;
$review-paragragh-margin\tablet: 10px 0 0;

