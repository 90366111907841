$banner__icon-fill: $color-accent-primary;

$banner__bg-color                       : #F2F1EC;

$banner__heading-background-image       : svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="77" height="3"><path fill="none" stroke="#E02329" stroke-dasharray="3 6" stroke-linecap="square" stroke-width="2" d="M1 2l80-.5"/></svg>');
$banner__heading-background-image-width : 77px;
$banner__heading-background-image-height: 8px;

$banner-slider-description-font-size: 20px;

$banner-slider-link-font-size: 16px;

$banner-slider-link-background-hover: $red-light;
$banner-slider-link-border-color: $red-light;


$banner-slider-description-font-weight: 500;

$banner-slider-link-line-height: 22px;

//tablet
$banner-slider-description-font-size\tablet: 16px;

$banner-slider-link-font-size\tablet: 13px;
