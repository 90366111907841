// Colors
//$red-light                  : #e02329;
//$red-dark                   : #891b23;

$red-light                  : #E11B22;
$red-dark                   : #D1453D;

// Semantic color scheme
$color-accent-primary       : $red-dark;
$color-accent-primary-dark  : $red-dark;
$color-accent-secondary     : $red-light;

// Typography
//$font-family-sans-serif     : 'Gotham A', 'Gotham B', sans-serif;
//$font-family-serif          : 'Archer A', 'Archer B', serif;

$font-family-sans-serif     : 'Gotham', sans-serif;
$font-family-serif          : 'Archer', serif;

$font-family-base           : $font-family-sans-serif;
$font-family-secondary      : $font-family-serif;

$font-size-base             : 14px;



