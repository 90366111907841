.banner {
    &__heading {
        .heading {
            font-weight: $font-weight-bold;
        }
    }

    &--category {
        .banner__heading {
            .heading {
                font-weight: $font-weight-medium;
                color: $color-accent-secondary;
            }
        }
    }
}
