.our-customer-content{
  position: relative;
  &:before{
    content: "";
    position: absolute;
    display: block;
    top: -40px;
    bottom: -40px;
    width: 100%;
    max-width: 880px;
    left: 50%;
    transform: translateX(-50%);
    background: #FFF;
    box-shadow: 2px 3px 6px 0 rgba(0,0,0,0.1);
    z-index: -1;
  }
  @include mq($max-screen: $screen-xl - 1){
    &:before{
      max-width: 800px;
    }
  }

  @include mq($max-screen: $screen-l - 1){
    &:before{
      top: -30px;
      bottom: -30px;
      max-width: 620px;
    }
  }

  @include mq($max-screen: $screen-l - 1){
    .title-swirl{
      padding: 0 30px;
    }
    &:before{
      max-width: calc(100% - 28px);
    }
  }

  @include mq($max-screen: $screen-m - 1){
    .title-swirl{
      max-width: inherit;
    }
  }

  .title-swirl{
    &:after,
    &:before{
      background-size: cover;
    }
  }

  &-paragragh{

    strong{
      font-weight: bold;
    }
  }
}

.scottish-smoked-salmon{
  .button{
    padding: 0 25px;
  }

  @include mq($min-screen: $screen-m, $max-screen: $screen-l - 1){
    .scottish-smoked-salmon-left{
      border: none !important;
    }
  }

  @include mq($max-screen: $screen-l - 1){
    .button{
      font-size: 14px;
    }
  }
  &-bigger-title{
    &:after{
      background-size: cover;
    }
  }
}

.yopto-reviews-container{
  .review-title{
    font-weight: 500;
  }
  .review-paragragh,
  .review-user-info{
    font-weight: 300;
  }
}

